*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body,
div,
html,
html a,
html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html li,
html p,
input,
span,
textarea {
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
:local(#app),
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: "tnum", "tnum";
  font-variant: tabular-nums;
}
body,
html {
  width: 100%;
  height: 100%;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:local(#app) {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;
  height: 100%;
}
.chipWrapper {
  > * {
    margin-right: 5px;
  }
}
// layout props globally
.button-primary {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  line-height: 1.5;
  font-size: 14px;
  height: 36px;
  user-select: none;
  position: relative;
  color: rgb(89, 89, 89);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  padding: 0px 25px;
  border-radius: 4px;
  border-color: rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  &:hover,
  &:focus {
    color: rgb(68, 130, 255);
    text-decoration: none;
    border-color: rgb(68, 130, 255);
  }
}
.slide-pane__overlay {
  z-index: 2;
}
// navigation overrides
.slide-pane .MuiBottomNavigation-root {
  background-color: rgba(black, 0.2);
  width: 100%;
  height: 100px;
  justify-content: space-between;
}
.ReactTable .rt-tbody .rt-td {
  line-height: 50px;
}
.children-fill-width > * {
  width: 100%;
}
[class*="layout-"] {
  box-sizing: border-box;
  display: flex;
}
[class*="-stretch"] {
  align-items: stretch;
  align-content: stretch;
}
[class*="-center-"] {
  justify-content: center;
}
[class*="-start-"] {
  justify-content: flex-start;
}
[class*="-end-"] {
  justify-content: flex-end;
}
[class*="-space-between-"] {
  justify-content: space-between;
}
[class*="-space-around-"] {
  justify-content: space-around;
}

[class*="-between-start"],
[class*="-center-start"],
[class*="-end-start"],
[class*="-space-around-start"],
[class*="-start-start"] {
  align-items: flex-start;
  align-content: flex-start;
}
[class*="-between-center"],
[class*="-center-center"],
[class*="-end-center"],
[class*="-space-around-center"],
[class*="-start-center"] {
  align-items: center;
  align-content: center;
  max-width: 100%;
}
[class*="-between-end"],
[class*="-center-end"],
[class*="-end-end"],
[class*="-space-around-end"],
[class*="-end-end"] {
  align-items: flex-end;
  align-content: flex-end;
}
.ReactTable .rt-tr,
.ReactTable .rt-tr-group {
  flex-grow: 0;
}
.is-new-item .action-button-archive {
  z-index: -1;
  opacity: 0;
}
.layout-row {
  flex-direction: row;
}
.layout-row-spaced {
  margin-bottom: 35px !important;
}
.layout-column {
  flex-direction: column;
}
.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.layout-wrap {
  flex-wrap: wrap;
}
.snackbar {
  .success {
    background-color: #8bc34a;
  }
  .error {
    background-color: #f50057;
  }
  .warning {
    background-color: #ff5722;
  }
  .info {
    background-color: #757575;
  }
}

.format-icon-container {
  overflow: hidden;
  white-space: nowrap;
}

.format-icon-vertical-align {
  vertical-align: middle;
}

.format-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-color: #ccc;
  color: #fff;
  border-radius: 0;
  position: relative;
  margin-right: 2px;
}

.format-icon:before {
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.75);
  text-align: center;
  width: 26px;
  line-height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.format-icon.super-skin-v2 {
  background-color: #0078d8 !important;
}

.format-icon.super-skin-v2:before {
  content: "SS";
}

.format-icon.hang-time {
  background-color: #ff743f !important;
}

.format-icon.hang-time:before {
  content: "HT";
}

.format-icon.hitchhike {
  background-color: #f44539 !important;
}

.format-icon.hitchhike:before {
  content: "HH";
}

.format-icon.top-tail {
  background-color: #5d32a9 !important;
}

.format-icon.top-tail:before {
  content: "TT";
}

.format-icon.subway {
  background-color: #212121 !important;
}

.format-icon.subway:before {
  content: "SW";
}

.format-icon.drive-by {
  background-color: #607d8b !important;
}

.format-icon.drive-by:before {
  content: "DB";
}

.format-icon.hover-board {
  background-color: rgba(143, 51, 170, 0.9) !important;
}

.format-icon.hover-board:before {
  content: "HB";
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiBox-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.paper {
  padding: 0px 15px;
}

.header-description {
  font-weight: 300;
  font-size: small;
}
